<template>
<div class="count-down container" v-if="check">
	<div class="row">
		<div class="col-md-6 col-12 text-md-right py-2 pr-md-4 text-primary">
			<div class="large pt-2 bold">{{tr('Become a participant of the event')}}</div>
			<div class="mt-2 fs-16">{{tr('Until the beginning remained')}}</div>
		</div>
		<div class="col-md-6 col-12 text-md-left my-3 text-secondary">
			<div class="row text-center">
				<div class="col-3">
					<div class="large">{{days.toString().padStart(2,'0')}}</div>
					<div class="mt-2 fs-16">{{tr('days')}}</div>
				</div>
				<div class="col-3">
					<div class="large">{{hours.toString().padStart(2,'0')}}</div>
					<div class="mt-2 fs-16">{{tr('hours')}}</div>
				</div>
				<div class="col-3">
					<div class="large">{{mins.toString().padStart(2,'0')}}</div>
					<div class="mt-2 fs-16">{{tr('minutes')}}</div>
				</div>
				<div class="col-3">
					<div class="large">{{secs.toString().padStart(2,'0')}}</div>
					<div class="mt-2 fs-16">{{tr('seconds')}}</div>
				</div>
			</div>
		</div>
	</div>
</div> 
</template>

<script>
export default {
	name: 'CountDown',
	inject: [
		'tr',
	],
	props: {
		date: String,
	},
	data: function () {
		return {
			timer: null,
			days: 0,
			hours: 0,
			mins: 0,
			secs: 0,
		};
	},
	created: function () {
		var rest = Math.floor((new Date(this.date).getTime() - Date.now()) / 1000);
		if(rest > 0) {
			this.days = Math.floor(rest / (24 * 60 * 60));
			rest -= this.days * 24 * 60 * 60;
			this.hours = Math.floor(rest / (60 * 60));
			rest -= this.hours * 60 * 60;
			this.minutes = Math.floor(rest / 60);
			this.secs = Math.floor(rest - this.minutes * 60);
			this.timer = setInterval(() => {this.secs--;}, 1000);
		}
	},
	computed: {
		check: function () {
			var val = this.days || this.hours || this.mins || this.secs;
			if(!val) {
				clearTimeout(this.timer);
			}
			return val;
		},
	},
	watch: {
		secs: function (val) {
			if(val < 0) {
				this.secs = 59;
				this.mins--;
			}
		},
		mins: function (val) {
			if(val < 0) {
				this.mins = 59;
				this.hours--;
			}
		},
		hours: function (val) {
			if(val < 0) {
				this.hours = 59;
				this.days--;
			}
		},
	},
}
</script>

<style>
.count-down {
	font-family: myriad-pro, sans-serif;
}
.count-down .large {
	font-size: 250%;
}
</style>
