export default {
	"Become a participant of the event": "Примите участие в мероприятии",
	"Until the beginning remained": "До начала осталось",
	"days": "дней",
	"hours": "часов",
	"minutes": "минут",
	"seconds": "секунд",

	"Add": "Добавить",
	"Add poll": "Добавить опрос",
	"All fields are required": "Все поля обязательны к заполнению",
	"Attempting multiple users to access the same account. Try reload page": "Попытка доступа нескольких пользователей по одниму аккаунту. Перезагрузите страницу",
	"Chat": "Чат",
	"Close": "Закрыть",
	"Collective application": "Коллективная заявка",
	"Company information": "Сведения об організации",
	"Confirmation of registration": "Подтверждение регистрации",
	"Contact person": "Контактное лицо, ответственное за оформление участников",
	"Country": "Страна",
	"Downloads": "Материалы для загрузки",
	"Enter your message": "Введите сообщение",
	"Entrance": "Вход",
	"Enter": "Войти",
	"Error": "Ошибка",
	"First name": "Имя",
	"Individual application": "Индивидуальная заявка",
	"Last name": "Фамилия",
	"Legal company name": "Юридическое название організации",
	"Login": "Логин",
	"Logout from online broadcast": "Выход из онлайн трансляции",
	"General": "Общий",
	"Medical specialty": "Врачебная специальность",
	"Middle name": "Отчество",
	"Not filled": "Не указано",
	"Online broadcast": "Онлайн-трансляция",
	"Participants": "Участники",
	"Participants registration": "Регистрация участников",
	"Participant": "Участник",
	"Participation format": "Формат участия",
	"Password": "Пароль",
	"Phone": "Телефон",
	"Please register as a participant": "Просим зарегистрировать в качестве участника",
	"Poll": "Опрос",
	"Poll creation": "Создание опроса",
	"Poll results": "Результаты опроса",
	"Polls management": "Управление опросами",
	"Position": "Должность",
	"Post address": "Почтовый адрес",
	"Prefered language": "Предпочтительный язык общения",
	"Promo code (if any)": "Промо-код (если есть)",
	"Registration": "Регистрация",
	"Remove": "Удалить",
	"Send": "Отправить",
	"Tax code": "Код ОКПО",
	"To enter the broadcast, use your personal link received from the conference organizers": "Для входа в трансляцию воспользуйтесь Вашей персональной ссылкой, полученной от организаторов конференции",
	"Total payment": "Итого к оплате",
	"Video": "Видео",
	"Wrong phone": "Хибний номер телефона",
	"Wrong e-mail": "Неверный e-mail",
	"Your application has been accepted and is being processed. Thank You for registering": "Ваша заявка принята и обрабатывается. Спасибо за регистрацию",
	"You will receive a special e-mail notification about your participation in the event shortly": "В ближайшее время Вы получите на электронную почту специальное сообщение о вашем участии в событии",

	"Belarus": "Белорусь",
	"Kazakhstan": "Казахстан",
	"Russia": "Россия",
	"Uzbekistan": "Узбекистан",
	"Ukraine": "Украина",
// 	"": "",
};
