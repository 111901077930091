<template>
<div class="dialog-box">
	<div class="modal-backdrop show"></div>
	<div class="modal show" @click.self="$emit('close', null)" @keyup.esc="$emit('close', null)">
		<div class="modal-dialog" :class="'modal-' + $props.size">
			<div class="modal-content">
				<div class="modal-header">
					<div class="modal-title"><slot name="header"></slot></div>
					<button type="button" class="close" @click="$emit('close', null)">×</button>
				</div>
				<div class="modal-body">
					<slot></slot>
				</div>
				<div class="modal-footer">
					<slot name="footer"></slot>
					<button type="button" class="btn btn-outline-secondary" @click="$emit('close', null);"><i class="fas fa-times"></i> {{tr('Close')}}</button>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
export default {
	name: 'ModalDialog',
	props: {
		size: String,
	},
	inject: [
		'tr',
	],
}
</script>

<style>
.dialog-box .modal.show {
	display: inherit;
}
.dialog-box .modal-backdrop {
	z-index: 1050;
}
.dialog-box .modal-title {
	font-weight: bold;
}
.dialog-box .modal-body {
	max-height: 75vh;
	overflow: auto;
}
</style>
