export default {
	"Become a participant of the event": "Візьміть участь у заході",
	"Until the beginning remained": "До початку лишилося",
	"days": "днів",
	"hours": "годин",
	"minutes": "хвилин",
	"seconds": "секунд",

	"Add": "Додати",
	"Add poll": "Додати опитування",
	"All fields are required": "Всі поля обов’язкові для заповнення",
	"Attempting multiple users to access the same account. Try reload page": "Спроба доступу кількох користувачів за одним аккаунтом. Перезавантажте сторінку",
	"Chat": "Чат",
	"Close": "Закрити",
	"Collective application": "Колективна заявка",
	"Company information": "Відомості про організацію",
	"Confirmation of registration": "Підтвердження реєстрації",
	"Contact person": "Контактна особа, відповідальна за оформлення учасників",
	"Country": "Країна",
	"Downloads": "Матеріали для завантаження",
	"Enter your message": "Введіть повідомлення",
	"Entrance": "Вхід",
	"Enter": "Увійти",
	"Error": "Помилка",
	"First name": "Ім’я",
	"Individual application": "Індивідуальна заявка",
	"Last name": "Прізвище",
	"Legal company name": "Юридична назва організації",
	"Login": "Логін",
	"Logout from online broadcast": "Вихід з онлайн трансляції",
	"General": "Загальний",
	"Medical specialty": "Лікарська спеціальність",
	"Middle name": "По батькові",
	"Not filled": "Не вказано",
	"Online broadcast": "Онлайн-трансляція",
	"Participants": "Учасники",
	"Participants registration": "Реєстрація учасників",
	"Participant": "Учасник",
	"Participation format": "Формат участі",
	"Password": "Пароль",
	"Phone": "Телефон",
	"Please register as a participant": "Просимо зареєструвати в якості учасника",
	"Poll": "Опитування",
	"Poll creation": "Створення опитування",
	"Poll results": "Результати опитування",
	"Polls management": "Керування опитуваннями",
	"Position": "Посада",
	"Post address": "Поштова адреса",
	"Prefered language": "Бажана мова спілкування",
	"Promo code (if any)": "Промо-код (якщо є)",
	"Registration": "Реєстрація",
	"Remove": "Видалити",
	"Send": "Відправити",
	"Tax code": "Код ЄДРПОУ",
	"To enter the broadcast, use your personal link received from the conference organizers": "Для входу в трансляцію скористайтеся Вашим персональним посиланням, отриманним від організаторів конференції",
	"Total payment": "Загалом до оплати",
	"Video": "Відео",
	"Wrong phone": "Хибний номер телефону",
	"Wrong e-mail": "Хибний e-mail",
	"Your application has been accepted and is being processed. Thank You for registering": "Ваша заявка  прийнята та обробляється. Дякуємо за реєстрацію",
	"You will receive a special e-mail notification about your participation in the event shortly": "Найближчим часом Ви отримаєте на електрону пошту спеціальне повідомлення щодо вашої участі у події",

	"Belarus": "Білорусь",
	"Kazakhstan": "Казахстан",
	"Russia": "Росія",
	"Uzbekistan": "Узбекистан",
	"Ukraine": "Україна",
// 	"": "",
};
