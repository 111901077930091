<script>
import ua from '../i18n/ua.js';
import ru from '../i18n/ru.js';

const locale = {
	ru,
	ua,
};

export default {
	provide: function () {
		return {
			lng: this.lng,
			tr: this.tr,
		};
	},
	data: function () {
		return {
			lang: 'ua',
		};
	},
	methods: {
		lng: function () {
			return this.lang;
		},
		tr: function (s, v) {
			if(locale[this.lang] && locale[this.lang][s]) {
				s = locale[this.lang][s];
			}
			if(v) {
				for(var i in v) {
					s = s.replace('{' + i + '}', v[i]);
				}
			}
			return s;
		},
	},
};
</script>
