<template>
<div class="login-form d-inline-block">
	<button class="logout btn btn-outline-success mx-2" @click="logout" v-if="key"><i class="fas fa-sign-in-alt"></i> {{tr('Logout from online broadcast')}}</button>
	<button class="login btn btn-outline-success mx-2 d-none" @click="show = true" v-else><i class="fas fa-sign-in-alt"></i> {{tr('Online broadcast')}}</button>
	<ModalDialog v-if="show" size="sm" @close="show = false">
		<template slot="header">{{tr('Entrance')}}</template>
		<form id="login-form" @submit.prevent="login">
			<div class="mb-2 small">{{tr('To enter the broadcast, use your personal link received from the conference organizers')}}</div>
			<div class="form-group">
				<label>{{tr('Login')}}</label>
				<input class="form-control" type="text" v-model="user" required>
			</div>
			<div class="form-group">
				<label>{{tr('Password')}}</label>
				<input class="form-control" type="password" v-model="pass" required>
			</div>
		</form>
		<template slot="footer">
			<button class="btn btn-outline-success" type="submit" form="login-form"><i class="fas fa-paper-plane"></i> {{tr('Enter')}}</button>
		</template>
	</ModalDialog>
</div>
</template>

<script>
import * as firebase from 'firebase/app';
import 'firebase/database';
import ModalDialog from './ModalDialog.vue';

export default {
	name: 'LoginForm',
	components: {
		ModalDialog,
	},
	props: {
		event: String,
	},
	inject: [
		'post',
		'tr',
	],
	data: function () {
		return {
			show: false,
			key: null,
			user: '',
			pass: '',
			token: '',
			count: 0,
		};
	},
	created: function () {
		firebase.database().ref(this.event + '/logins').on("child_added", this.check);
		firebase.database().ref(this.event + '/logins').on("child_removed", this.recnt);
	},
	beforeDestroy: function () {
		console.log('beforeDestroy');
		this.logout();
	},
	mounted: function () {
		this.token = this.param('token');
		if(this.token != '') {
			this.tokin();
		}
	},
	methods: {
		recnt: function () {
			this.count--;
		},
		check: function (data) {
// 			console.log(data.key, data.val());
			var val = data.val();
			if(val.user.name == this.user.name && this.key && data.key != this.key) {
				firebase.database().ref(this.event + '/logins').child(data.key).remove();
				this.logout();
				this.$emit('error', this.tr('Attempting multiple users to access the same account. Try reload page'));
			} else {
				this.count++;
			}
		},
		tokin: function () {
			this.post({
				"query": {
					"object_id": this.token,
					"method": "check_participant",
					"include": [
						{
							"method": "promo"
						},
						{
							"method": "conf_link"
						}
					]
				}
			}).then((data) => {
				console.log(data);
				if(data.resultcode == 200 && data.check_participant.result.length && this.event == data.check_participant.result[0].conf_name) {
					this.user = {
						name: (data.check_participant.result[0].first_name + ' ' + data.check_participant.result[0].last_name).trim(),
						job: data.check_participant.result[0].job,
						position: data.check_participant.result[0].position,
						moder: data.check_participant.result[0].is_moderator,
					};
					var conf = {
						user: this.user,
						stream: data.check_participant.result[0].conf_link.result,
						pdf: data.check_participant.result[0].pdf_link,
					};
					if(data.check_participant.result[0].promo.result.length) {
						conf.video = data.check_participant.result[0].promo.result[0].promo_link;
					}
					this.$emit('input', conf);
					this.key = firebase.database().ref(this.event + '/logins').push({
						time: (new Date()).toISOString(),
						user: this.user,
					}).key;
				}
			});
		},
		login: function () {
			this.post({
				"query": {
					"method": "check_participant",
					"filter": {
						"conf_name": this.event,
						"login": this.user,
						"pass": this.pass,
					}
				}
			}).then((data) => {
				console.log(data);
				if(data.resultcode == 200 && this.event == data.check_participant.result[0].conf_name) {
					this.user = {
						name: data.check_participant.result[0].first_name + ' ' + data.check_participant.result[0].last_name,
						job: this.job,
						position: this.position,
						moder: data.check_participant.result[0].is_moderator,
					};
					var conf = {
						event: this.event,
						user: this.user,
						stream: data.check_participant.result[0].conf_link,
						pdf: data.check_participant.result[0].pdf_link,
					};
					if(data.check_participant.result[0].promo) {
						conf.video = data.check_participant.result[0].promo.result[0].promo_link;
					}
					this.$emit('input', conf);
					this.show = false;
					this.key = firebase.database().ref(this.event + '/logins').push({
						time: (new Date()).toLocaleString(),
						user: this.user
					}).key;
					this.pass = '';
				} else {
					if('errormessage' in data) {
						this.$emit('error', data.errormessage);
					}
				}
			});
		},
		logout: function () {
			if(!this.user) return;
			firebase.database().ref(this.event + '/logins').child(this.key).remove(() => {
				console.log('logout ' + this.key);
				this.$emit('input', null);
				this.token = '';
				this.user = '';
				this.pass = '';
				this.key = null;
				this.count--;
			});
		},
		param: function (name) {
			var v = window.location.search.split(new RegExp('\\b' + name + '='))[1];
			return v !== undefined ? decodeURIComponent(v.split('&')[0]) : '';
		},
	},
	watch: {
		count: function () {
			this.$emit('count', this.count);
		},
	},
}
</script>

<style>
.login-form .logout {
	display: none;
}
</style>
